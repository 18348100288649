<!--
- bgText: text - not used
- backgroundImage: image - not used
-->
<template>
	<div class="cs-interactive-contact-interface cs-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<div class="row">
                    <div class="col-lg-6">
                        <CSMap :zoom="data.zoom" :center="coordinates"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution:='&copy;
                        <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>
                        contributors' />
                    </div>
                    <div class="col-lg-6">
                        <div class="map-content" v-if="data.title || data.subTitle || data.text || data.buttons">
                            <h2 v-if="data.title" class="cs-title">
                                {{ data.title }}
                            </h2>
                            <h3 v-if="data.subTitle" class="cs-sub-title">
                                {{ data.subTitle }}
                            </h3>
                            <span v-if="data.text" class="cs-text" v-html="data.text"></span>

                            <div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))">
                                <Buttons :data="data.buttons" :cs_style="data.style" />
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Contact",
	components: {
		CSMap: () => import("../components/CSMap.vue"),
		Buttons: () => import("../components/CSUtilsButtons.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {}
	},
	computed: computed('InteractiveContactInterface')
}
</script>
<style scoped lang="scss">
@import "../styles/main.scss";
.cs-block.cs-interactive-contact-interface {
	::v-deep {
		.leaflet-container {
			height: 500px !important;
			@media (max-width: 991px) {
				height: 300px !important;
			}
		}
	}
    .map-content {
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }

}
</style>
